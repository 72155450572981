<template>
  <div>
         <div class="search">
        <div class="title">分销列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="订单号:">
            <el-input v-model="params.coding" ></el-input>
        </el-form-item>
        <el-form-item label="联系人:">
            <el-input v-model="params.linkMan" ></el-input>
        </el-form-item>
        <el-form-item label="联系方式:">
            <el-input v-model="params.linkWay" ></el-input>
        </el-form-item>
           <el-form-item label="状态:">
          <el-select clearable v-model="params.status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

            <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item>
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        align="center">
         <!-- <el-table-column type="selection" width="55"  align="center"> </el-table-column> -->
        <el-table-column type="index" width="50"   align="center"> </el-table-column>
        <el-table-column
        prop="orderId"
        label="订单ID"
        
        align="center">
        </el-table-column>
        <!-- <el-table-column
        label="头像 "
        width="180"
        align="center">
        <template slot-scope="scope">
            <el-image 
                style="width: 70px; height: 70px"
                :src="scope.row.headImg" 
                :preview-src-list="[scope.row.headImg]">
            </el-image>
        </template>
        </el-table-column> -->
         <el-table-column
        prop="coding"
        label="订单号"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="prodName"
        label="商品名称"
         
        align="center">
        </el-table-column>
         <el-table-column
        prop="addTime"
        label="下单时间"
        width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="beginTime"
        label="游玩时间"
         width="200"
        align="center">
        </el-table-column>
         <el-table-column
        prop="linkMan"
        label="联系人"
         
        align="center">
        </el-table-column>
         <el-table-column
        prop="linkWay"
        label="联系方式"
      
        align="center">
        </el-table-column>
         <el-table-column
        prop="strStatus"
        label="分销状态"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="prodName"
        label="商品名称"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="endFee"
        label="订单总额"
         width="180"
        align="center">
        </el-table-column>
         <el-table-column
        prop="profitFee"
        label="所得佣金"
         width="180"
        align="center">
        </el-table-column>
        <!-- <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="record(scope.row)">兑换记录</el-button>
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="code(scope.row)">生成小程序码</el-button>
            <el-button type="primary"  size="mini" @click="appoint(scope.row)">指定发放</el-button>
        </template>
        </el-table-column> -->
    </el-table>
         <div class="block">
         <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
  </div>
</template>

<script>
import { fxList } from '../../api/balanceJournalDetails.js'
export default {
    data() {
        return {
            total: 0,
            
            params: {
                currentPage: 1,
                pageSize: 5,
                linkMan: '',
                linkWay: '',
                beginTime: '',
                endTime: '',
                coding:'',
                userId:0,
                status:0

            },
            // 0 全部、1 待收益、2 已失效、3 已结算 下拉框
              statusOptions: [
                {
                value: 0,
                label: "全部",
                },
                {
                value: 1,
                label: "待收益",
                },
                {
                value: 2,
                label: "已失效",
                },
                {
                value: 3,
                label: "已结算",
                },
            ],
            tableData: [],
            date: null,
            pickerOptions0: {
            disabledDate(time) {
                return time.getTime() > Date.now() - 8.64e6
            },
         }
        }
    },
    created(){
          this.params.userId = this.$route.query.userId
        this.getList()
      
    },
    methods: {
        async getList(){
            this.params.beginTime = this.date ?  this.date[0] : ''
            this.params.endTime = this.date ? this.date[1] : ''
            const {data} = await fxList(this.params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.$message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        reset(){
           
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.phone = ''
            this.params.beginTime = ''
            this.params.endTime = ''
            this.date = null
        },
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 80%;
}
</style>